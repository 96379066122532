body {
    margin: 0;
    font-family: 'RedHat-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'RedHat-Regular';
    src: url('assets/fonts/RedHat-Regular.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'RedHat-Light';
    src: url('assets/fonts/RedHat-Light.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'RedHat-Bold';
    src: url('assets/fonts/RedHat-Bold.ttf') format('truetype');
    font-style: normal;
}
